export const setAxiosConfig = (config, uri, type = 'get', params = [], upload = false) => {

    let endpoint = config.api.endpoint;
    if(config.api.useport && config.api.port){
        endpoint += ':' + config.api.port;
    }
    
    if(!endpoint)
    {
        return false;
    }

    let token = localStorage.getItem('token') ?? false;
    let tokenData = JSON.parse(token);

    const axiosConfig = {
        method: type,
        url: endpoint + uri,
        data: params,
        headers: {
            "Content-Type": upload ? "multipart/form-data" : "application/json",
            "x-api-key": config.api.pubkey,
            "x-token-key": tokenData ? tokenData['token'] : 0,
            "x-token-id": tokenData ? tokenData['id'] : 0
        }
    }

    return axiosConfig;

}