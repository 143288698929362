import React, { useState } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import FormButton from '../../components/Forms/FormButton';

import Fader from '../../components/Loaders/Fader';

const WarningPopup = ({
    actionLabel = 'Delete',
    title = 'Deleting User',
    text = 'Are you sure you want to continue? This action cannot be reversed.', 
    actionTitle = 'Deleting', 
    actionText = 'The process should take no longer than 30 seconds. Please Wait...',
    onSubmitHandler,
    onCancelHandler
}) => {

    const [action,setAction] = useState(false);

    const config = useSelector(state => state.config.config);

    const handleSubmit = () => {
        setAction(true);
        onSubmitHandler();
    }

    return (
        <div className='login-wrap'>
            <div className='login-view'>
                <div className='login-box warning'>
                    { !action ? 
                        <div className='logo-large'>{title}</div> : 
                            <>
                            <div className='logo-large'>{actionTitle}</div> 
                            <div className='logo-sub user-space'>{actionText}</div>
                            </>
                    }
                    {
                        !action ? 
                            <>
                                <div className='popup-text'>
                                    {text}
                                </div>
                                 <div className='submit delete'>
                                    <FormButton subLink={'Cancel'} subLinkClick={onCancelHandler} text={actionLabel} onClick={handleSubmit} />
                                </div>
                            </>
                        
                        : 
                            <Fader size={50} />
                    }
                </div>
            </div>
        </div>
    )
}

export default WarningPopup;