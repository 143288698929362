import React, {useState, useLayoutEffect, useEffect, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../../../assets/images/icons/user.svg';

import CircularChart from '../../components/Charts/CircularChart';
import OverallChartComponent from '../../components/Charts/OverallChart';
import LineChartComponent from '../../components/Charts/LineChart';
import WordCloudComponent from '../../components/Charts/WordCloud';

import { BACKGROUND_EFFECT, SESSION_STATE, USER_DATA, USER_ROLES } from '../../../util/constants/AppConstants';

import Fader from '../../components/Loaders/Fader';

import axios from 'axios';

import Logo from '../../../assets/images/logo.png';
import TrendIcon from '../../../assets/images/icons/trend.svg';
import TopicIcon from '../../../assets/images/icons/topic.svg';
import MentionsIcon from '../../../assets/images/icons/mentions.svg';
import AddIcon from '../../../assets/images/icons/add-white.svg';
import ArrowRight from '../../../assets/images/icons/arrow-right-white.svg';

import EngagementIcon from '../../../assets/images/icons/engagement.svg';
import HashtagIcon from '../../../assets/images/icons/hashtag.svg';
import ShareIcon from '../../../assets/images/icons/share.svg';
import KeywordIcon from '../../../assets/images/icons/keyword.svg';

import TopicItem from '../../components/TopicItem';
import SocialPosts from '../../components/Social/SocialPosts';
import DataGridComponent from '../../components/Charts/DataGrid';

import DefaultUser from '../../../assets/images/tmp/default-user.jpg';

import { setSession } from '../../../datastore/actions/sessionActions';
import { setBackgroundEffect } from '../../../datastore/actions/settingsActions';
import { setNotify } from '../../../datastore/actions/actionActions';
import { getCustomState, saveCustomState } from '../../../util/LocalStorage';

import SwitchInput from '../../components/Forms/Switch';
import AppNotify from '../../components/AppNotify';

import { deleteCustomState } from '../../../util/LocalStorage';
import { setAxiosConfig } from '../../../util/SetDefaultLists';

import AppDatabase from '../../../datastore/app/app-data.json';

import Table from '../../components/Table/Table';
import UploadData from '../../components/Uploader/UploadData';

import FormButton from '../../components/Forms/FormButton';
import InputItem from '../../components/Forms/InputItem';

const Dashboard = () => {

    const div = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const config = useSelector(state => state.config.config);
    const actions = useSelector(state => state.action);
    const appData = AppDatabase;

    const backgroundEffectFormInputProps = { inputProps: { 'aria-label': 'Background Colour' } };
    const backgroundEffectState = useSelector(state => state.settings.backgroundEffect);

    const [backgroundEffectChecked,setBackgroundEffectChecked] = useState(backgroundEffectState);
    const [booleanQuery,setBooleanQuery] = useState('');
    const [isBooleanQueryLoading,setIsBooleanQueryLoading] = useState(true);
    const [isBooleanQueryEdit,setIsBooleanQueryEdit] = useState(false);
    const [isBooleanSaving,setIsBooleanSaving] = useState(false);
    const [newDataSource,setNewDataSource] = useState('');
    const [isCreatingDataSource,setIsCreatingDataSource] = useState(false);

    const [userDataState,setUserDataState] = useState(false);
    const [userInfo,setUserInfo] = useState(false);

    const [nameData,setNameData] = useState(false);
    const [searchId,setSearchId] = useState(0);

    const [defaultNameDataId,setDefaultNameDataId] = useState(false);

    const [notifyNum,setNotifyNum] = useState(0);

    const [dataPreviewLoading,setDataPreviewLoading] = useState(true);
    const [sourceData,setSourceData] = useState(false);

    const [isNameDataDropdownOpen,setIsNameDataDropdownOpen] = useState(false);

    const [isCreateNewSourceOpen,setIsCreateNewSourceOpen] = useState(false);

    const [isUserMenuOpened,setIsUserMenuOpened] = useState(false);

    const [menuLoading,setMenuLoading] = useState(true);
    const [menu,setMenu] = useState(appData['menu']);

    const [newBooleanText,setNewBooleanText] = useState('');

    const [defaultDataSourceName,setDefaultDataSourceName] = useState(false);

    const [isSyncSourceOpen,setIsSyncSourceOpen] = useState(false);
    const [isSyncing,setIsSyncing] = useState(false);
    const [isSyncMergeChecked,setIsSyncMergeChecked] = useState(false);
  
    const handleOpenUserMenu = () => {
        if(!isUserMenuOpened){
            setIsUserMenuOpened(true);
            return;
        }
        setIsUserMenuOpened(false);
    }

    const handleLogout = () => {
        deleteCustomState(SESSION_STATE);
        setIsUserMenuOpened(false);
        dispatch(setSession(false));
    }

    const handleChangeBackgroundStatus = (status) => {
        saveCustomState(BACKGROUND_EFFECT,status);
        dispatch(setBackgroundEffect(status));
    }

    const handleEditBoolean = () => {
        if(isBooleanQueryEdit){
            dispatch(setNotify({
                status: true,
                type: 'success',
                title: 'Success',
                message: 'You have successfully saved the Boolean Query.'
            }));
            setIsBooleanQueryEdit(false);
            return;
        }
        setIsBooleanQueryEdit(true);
    }

    const checkSyncMerge = () => {
        setIsSyncMergeChecked(true)
    }

    const uncheckSyncMerge = () => {
        setIsSyncMergeChecked(false)
    }

    const handleEditBooleanText = (e) => {
        const value = e.currentTarget.value;
        setBooleanQuery(value);
    }

    const handleNewBooleanText = (e) => {
        const value = e.currentTarget.value;
        setNewBooleanText(value);
    }

    const hanldeSaveBooleanData = () => {
        setIsBooleanSaving(true);
        axios(
            setAxiosConfig(config, '/data/boolean', 'post', {
                data_name: searchId,
                value: encodeURIComponent(booleanQuery)
            })
        ).then(
            res => {
                if(res.data.status === 'success'){
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    }));
                }else {
                    dispatch(setNotify({
                        status: true,
                        type: 'error',
                        title: 'Error',
                        message: res.data.message
                    }));
                }
                setIsBooleanSaving(false);
                setIsBooleanQueryEdit(false);
            }
        ).catch(err => {
            console.log(err)
        })
    }

    const handleGetSourceData = (id) => {

        axios(
            setAxiosConfig(config, '/data/posts?latest=false&id=' + id)
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setDataPreviewLoading(false);
                    setSourceData(res.data.data);
                }else {
                    console.log('ERROR: Grabbing Top Posts');
                }
            }
        ).catch(err => {
            console.log(err)
        })

    }


    const handleGetBooleanQuery = (id) => {
        setIsBooleanQueryLoading(true);
        axios(
            setAxiosConfig(config, '/data/boolean/' + id)
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setIsBooleanQueryLoading(false);
                    res.data.data.length > 0 ?
                        setBooleanQuery(decodeURIComponent(res.data.data))
                    : setBooleanQuery('No Saved Boolean Query')
                }else {
                    console.log('ERROR: Grabbing Boolean Query');
                }
            }
        ).catch(err => {
            console.log(err)
        })

    }

    const handleGetNameData = () => {
        axios(
            setAxiosConfig(config, '/data/names')
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setNameData(res.data.data);
                    setDefaultDataSourceName(res.data.data[0].name);
                    setDefaultNameDataId(res.data.data[0].id);
                    handleGetSourceData(res.data.data[0].id);
                    setSearchId(res.data.data[0].id);
                }else {
                    console.log('ERROR: Grabbing Top Posts');
                }
            }
        ).catch(err => {
            console.log(err)
        })
    }

    const handleOpenNameDataSourceDropdown = ()  => {
        setIsNameDataDropdownOpen(true);
    }

    const handleCloseNameDataSourceDropdown = ()  => {
        setIsNameDataDropdownOpen(false);
    }

    const handleGetNameDataSource = (id) => {
        setIsNameDataDropdownOpen(false);
        setDataPreviewLoading(true);
        handleGetSourceData(id);
        handleGetBooleanQuery(id);
        nameData.map((item) => {
            if(item.id === id){
                setDefaultDataSourceName(item.name);
                setDefaultNameDataId(item.id);
            }
        })
    }

    const handleCreateNameDataSource = () => {
        setIsCreateNewSourceOpen(true);
    }

    const handleCreateNameDataSourceClose = () => {
        setIsCreateNewSourceOpen(false);
    }

    const setNewDataSourceHandler = (e) => {
        setNewDataSource(e.currentTarget.value);
    }

    const handleSyncSourceOpen = () => {
        setIsSyncSourceOpen(true);
    }

    const handleSyncSourceClose = () => {
        setIsSyncSourceOpen(false);
    }

    const handleScheduleSyncSource = () => {
        setIsSyncing(true)
        axios(
            setAxiosConfig(config, '/data/names/sync', 'post', {
                sync_merge: isSyncMergeChecked
            })
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setIsSyncSourceOpen(false)
                    handleCloseNameDataSourceDropdown()
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: 'Meltwater Sync Has Been Scheduled. Please wait a few minutes and refresh.'
                    }))
                }else {
                    dispatch(setNotify({
                        status: true,
                        type: 'error',
                        title: 'Error',
                        message: res.data.message
                    }));
                }

                setIsSyncing(false)
            }
        ).catch(err => {
            console.log(err)
        })

 
    }

    const handleCreateDataSource = () => {
        setIsNameDataDropdownOpen(false);
        setIsCreateNewSourceOpen(false);
        setIsCreatingDataSource(true);
        setIsBooleanQueryLoading(true);
        setBooleanQuery(null);
        axios(
            setAxiosConfig(config, '/data/names/create', 'post', {
                name: newDataSource,
                boolean: newBooleanText
            })
        ).then(
            res => {
                if(res.data.status === 'success'){
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    }));
                    handleGetNameData(res.data.data.id);
                }else {
                    dispatch(setNotify({
                        status: true,
                        type: 'error',
                        title: 'Error',
                        message: res.data.message
                    }));
                }
                setIsNameDataDropdownOpen(false);
                setIsCreateNewSourceOpen(false);
                setIsCreatingDataSource(false);
                setIsBooleanQueryLoading(false);
            }
        ).catch(err => {
            console.log(err)
        })
    }

    const tableHeader = [
        {
            Header: 'Date',
            accessor: 'date',
            class: 'date-th'
        },
        {
            Header: 'Headline',
            accessor: 'headline',
            class: 'headline-th'
        },
        {
            Header: 'URL',
            accessor: 'url',
            class: 'url-th'
        },
        {
            Header: 'Opening Text',
            accessor: 'opentext',
            class: 'opentext-th'
        },
        {
            Header: 'Hit Sentence',
            accessor: 'hit',
            class: 'hit-th'
        }
    ]

    const sourceTableColumns = useMemo(() => tableHeader);


    const handleMenuSelection = (slug,label) => {
        navigate('/' + slug);
    }

    
    useEffect(() => {
        if(appData){
            setMenuLoading(false);
        }
    }, [appData]);

    useEffect(() => {
        setBackgroundEffectChecked(backgroundEffectState);
    }, [backgroundEffectState])


    useEffect(() => {
        if(!booleanQuery && config.api && !isBooleanQueryEdit)
        {
            if(searchId!==0){
                handleGetBooleanQuery(searchId);
            }
        }
    }, [booleanQuery,config,searchId]);

    useEffect(() => {
        setUserDataState(getCustomState(USER_DATA, true));
    }, [])

      useEffect(() => {        
        if(config.api  && dataPreviewLoading){ handleGetNameData() }
      }, [config,nameData])

      useEffect(() => {
        if(!userInfo && userDataState && config.api && !actions.loggingIn){
            setUserInfo(userDataState);
        }
    }, [userDataState,userInfo,config,actions]);

    return <>
        <AppNotify />
        <div className='platform-frame'>
            <div className='pf-left'>
                <div className='logo'>
                    <img src={Logo} />
                    <div className='logo-sub'>Admin Portal</div>
                </div>
                <div className='menu'>
                    {
                        !menuLoading ?
                            menu.map((item) => {
                                return <TopicItem data={item} topicHandler={handleMenuSelection} />
                            }) : <Fader />
                    }
                </div>
                <div className='menu-footer'>
                    <div className='disclaimer'>
                        &copy; 2024 TAGA Group Inc.
                    </div>
                </div>
            </div><div className='pf-right'>
                <div ref={div} className='pfr-header'>
                    <div className='dashboard-title'>
                        Searches
                    </div><div className='dashboard-menu'>
                        <div className='dm-left'>
                            {
                                /**
                                 *  @ Feature Flag :: OFF
                                 */
                            /*
                            <span>
                                <div></div>
                                <div></div>
                                <div></div>
                            </span>*/
                        }
                        </div><div className='dm-right' onClick={handleOpenUserMenu}>
                            <div className='user-icon'>
                                { notifyNum > 0 ? <div className='notify-num'>{notifyNum}</div> : '' }
                                <img src={UserIcon} />
                            </div>
                        </div>
                    </div>
                    {
                        isUserMenuOpened ? 
                            <>
                                <div className='user-menu'>
                                    <div className='user-frame'>
                                        <div className='username'>
                                            { userInfo ? 
                                                <>
                                                {userInfo.firstname} {userInfo.lastname} <span>{
                                                    USER_ROLES.map((role) => {
                                                        if(role.value == userInfo.role){
                                                            return role.short
                                                        } 
                                                    })
                                                }</span>
                                                </> : <Fader />
                                            }
                                        </div>
                                    </div>
                                    <div className='user-image'>
                                        {userInfo ? Array.from(userInfo.firstname)[0] + Array.from(userInfo.lastname)[0] : '...'}
                                    </div>
                                    <div className='user-info'>
                                        <span className='email'>{userInfo ? userInfo.email : '...'}</span>
                                        <span className='org'>{userInfo ? userInfo.job : '...'}</span>
                                    </div>
                                    <div className='logout'>
                                        <span onClick={handleLogout}>Logout</span>
                                    </div>
                                </div>
                            </> : ''
                    }
                </div>
                <div className='dashboard-components'>
                    <div className='dc-left full'>

                    <div className='search-selection'>
                            <div className={isNameDataDropdownOpen ? 'ss-object open' : 'ss-object'}>
                                {

                                    !isCreatingDataSource ? 
                                        <>
                                            {
                                                nameData ?
                                                    <><span onClick={isNameDataDropdownOpen ? handleCloseNameDataSourceDropdown : handleOpenNameDataSourceDropdown}>
                                                        <img src={TopicIcon} />{defaultDataSourceName}
                                                    </span></>
                                                : ''
                                            }
                                            {
                                                isNameDataDropdownOpen ?
                                                    <>
                                                        <div className='ss-dropdown'>
                                                            <div className='ss-dropdown-list'>
                                                                {
                                                                    nameData ?
                                                                        nameData.map((name) => {
                                                                            return <span onClick={() => handleGetNameDataSource(name.id)}>
                                                                                <img src={TopicIcon} />{name.name}</span>
                                                                        }) : ''
                                                                }
                                                            </div>
                                                            <span className='create-new sync' onClick={handleSyncSourceOpen}><img src={ArrowRight} />
                                                                Sync
                                                            </span>
                                                            <span className='create-new' onClick={handleCreateNameDataSource}><img src={AddIcon} />
                                                                { isCreateNewSourceOpen ? <></> : 'Create New' }
                                                            </span>
                                                        </div>
                                                    </> : ''
                                            }
                                        </> : 
                                            <div className='creating-source-fader'>
                                                <Fader />
                                            </div>                                
                                 }
                                </div>
                                
                            </div>

        
                        <div className='section-components top-space'>
                            

                            <div className='sentiment-source chart-component full-width'>
                                <div className='title-top'>
                                    <div className='tt-left'>
                                        <div className='chart-title align-left'>
                                        <img src={MentionsIcon} />  Boolean Query
                                        </div>
                                    </div><div className='tt-right'>
                                    {
                                        !isBooleanQueryLoading ?
                                            <>
                                            <div className={ isBooleanQueryEdit ? 'edit-btn save' : 'edit-btn'} onClick={ 
                                                isBooleanQueryEdit ? hanldeSaveBooleanData : handleEditBoolean
                                            }>
                                                {isBooleanQueryEdit ? 
                                                    isBooleanSaving ? <Fader /> : 'Save' 
                                                    : 'Edit' }
                                            </div>
                                            </> : ''
                                    }
                                    </div>
                                </div>
                                {
                                    isBooleanQueryLoading ? 'Loading..' : 
                                    isBooleanQueryEdit ?
                                        <textarea className='boolean-query' onChange={handleEditBooleanText}>{booleanQuery}</textarea> : <div className='boolean-query-frame'>{booleanQuery}</div>
                                }
                            </div>
                        </div>
                        
                        <div className='sentiment-trend chart-component chart-extend'>
                            <div className='title-top'>
                                <div className='tt-left'>
                                    <div className='chart-title align-left'>
                                        Latest Data
                                    </div>
                                </div>
                            </div>

                            {
                                !dataPreviewLoading ? 
                                    <Table columns={sourceTableColumns} data={sourceData} /> : 
                                    <div className='sentiment-charts'>
                                        <Fader />
                                    </div>
                            }
                        </div>


                    </div>

                    {
                                
                                isCreateNewSourceOpen ? 
                                        <>
                                        <div className='login-wrap user-add-wrap'>
                                            <div className='login-view'>
                                                <div className='login-box add-user-box'>
                                                    <div className='logo-large'>
                                                        Create Search
                                                    </div>
                                                    <div className='searchForm'>
                                                        <InputItem label='Search Name' type={'text'} icon={MentionsIcon} onChange={setNewDataSourceHandler} value={newDataSource} />
                                                        <textarea className='boolean-query' onChange={handleNewBooleanText}>{newBooleanText}</textarea>
                                                    </div>
                                                    <div className='submit delete'>
                                                        <FormButton subLink={'Cancel'} subLinkClick={handleCreateNameDataSourceClose} text={'Save'} onClick={handleCreateDataSource} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ''
                        }


                        {
                                
                                isSyncSourceOpen ? 
                                        <>
                                        <div className='login-wrap user-add-wrap'>
                                            <div className='login-view'>
                                                <div className='login-box warning'>
                                                   
                                                    <div className='logo-large'>
                                                        Sync Searches from Meltwater
                                                    </div>
                                                    <div className='searchForm'>
                                                    {
                                                        !isSyncing ?
                                                            <>
                                                                    <span>
                                                                        This will sync searches from Meltwater and bring missing IDs from Meltwater locally by Search Name. 
                                                                        If you only want to sync the ones from our database, leave "Sync Merge" unchecked (recommended).
                                                                    </span>
                                                                <div className='options'>
                                                                    <div className='checkarea checked'>
                                                                        {
                                                                            isSyncMergeChecked ?
                                                                                <input type='checkbox' value='merge' checked onChange={uncheckSyncMerge} /> :
                                                                                <input type='checkbox' value='merge' onChange={checkSyncMerge} />
                                                                        }
                                                                        <label>Sync Merge</label>
                                                                    </div>
                                                                </div>
                                                                <div className='submit delete'>
                                                                    <FormButton subLink={'Cancel'} subLinkClick={handleSyncSourceClose} text={'Sync'} onClick={handleScheduleSyncSource} />
                                                                </div>
                                                               </> : 
                                                                <div className='sentiment-charts sync-search'>
                                                                    <Fader />
                                                                </div>   
                                                    }
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </> : ''
                        }


                </div>

                <div className='platform-footer'>
                    <div className='version-num'>
                        v0.0.1
                    </div>
                </div>
            </div>
                
        </div>
        <div className="background-elem"></div>
    </>;
}

export default Dashboard;