import React from 'react'

const SelectItem = ({onChangeHandler, label, options, icon = ''}) => {


    return (
        <>
        <div className='select-area'>  
            <select onChange={onChangeHandler}>
                <option>{label}</option>
                {
                    options.map((option) => {
                        return <option value={option.value}>{option.label}</option>
                    })
                }
            </select>
            {
                icon ?
                    <div className='input-icon'>
                        <img src={icon} />
                    </div> : ''
            }
        </div>
        </>
    )

}

export default SelectItem;