import React from 'react';

const InputItem = ({type = 'text', label = '', icon = false, validate = true, onChange, value = ''}) => {

    const handleOnChange = (e) => {
        onChange(e);
    }

    return (
        <>
            <div className='input'>
                <div className='input-icon'>
                    <img src={icon} />
                </div>
                <input type={type} placeholder={label} onChange={handleOnChange} value={value} />
            </div>
        </>
    )

}

export default InputItem;