import React from 'react';

import { CircularProgress } from '@mui/material';

const CircularChart = ({icon,color = '#444', value = 32}) => {

    return ( 
        <div className='circle-chart'>
            <CircularProgress 
                    variant="determinate" 
                    value={value} 
                    sx={{
                        color: {color},
                        position: 'absolute',
                        left: 0,
                    }}
                    size={100}
                    thickness={5}
            />
            <div className='chart-icon'>
                <img src={icon} />
            </div>
        </div>
    );
}

export default CircularChart;