import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserIcon from '../../../assets/images/icons/user.svg';
import UserAdd from '../UserAdd/UserAdd';

import { USER_ROLES } from '../../../util/constants/AppConstants';

import WarningPopup from '../Popup/Warning';

import axios from 'axios';
import { setAxiosConfig } from '../../../util/SetDefaultLists';
import { setNotify } from '../../../datastore/actions/actionActions';

const UserProfile = ({data, onDeleteHandler}) => {

    const dispatch = useDispatch();

    const config = useSelector(state => state.config.config);

    const [firstname,setFirstname] = useState(data.firstname);
    const [lastname,setLastname] = useState(data.lastname);
    const [status,setStatus] = useState(data.status);
    const [jobTitle,setJobTitle] = useState(data.job_title);
    const [email,setEmail] = useState(data.email);
    const [city,setCity] = useState(data.location_city);
    const [country,setCountry] = useState(data.location_country);
    const [locationState,setLocationState] = useState(data.location_state);
    const [role,setRole] = useState(false);
    const [deleteRequest,setDeleteRequest] = useState(false);

    const [userEditing,setUserEditing] = useState(false);

    const handleUserEdit = () => {
        setUserEditing(true);
    }

    const handleUserEditClose = () => {
        setUserEditing(false);
    }

    const handleUserDeleteRequest = () => {
        setDeleteRequest(true);
    }

    const handleUserDeleteClose = () => {
        setDeleteRequest(false);
    }

    const handleUserDelete = () => {
        axios(
            setAxiosConfig(config, '/users/' + data.id + '/delete', 'delete')
        ).then(
            res => {
                if(res.data.status === 'success'){
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    }));
                }else {
                    dispatch(setNotify({
                        status: true,
                        type: 'error',
                        title: 'Error',
                        message: res.data.message
                    }));
                }
                onDeleteHandler();
            }
        ).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(!role){
            USER_ROLES.map((item) => {
                if(data.role === item.value){
                    setRole(item.short);
                }
            });
        }
    }, [role]);

    return (
        <>
        <div className='user-profile'>
            <div className='user-profile-edits'>
                <div className='edit-btn' onClick={handleUserEdit}>
                    Edit
                </div><div className='edit-btn warning' onClick={handleUserDeleteRequest}>
                    Delete
                </div>
            </div>
            <div className='user-profile-left'>
                <div className='user-profile-icon'>
                    <img src={UserIcon} />
                </div>
                { role ? <span>{role}</span> : ''}
            </div><div className='user-profile-right'>
                <div className='user-profile-name'>
                    {firstname} {lastname} <span className='user-profile-status'>{status == 2 ? 'Active' : 'Inactive'}</span>
                </div>
                <div className='user-profile-job'>
                    {jobTitle}
                </div>
                <div className='user-profile-data'>
                    <div className='user-info-item'>
                        <div className='uii-top'>
                            Email
                        </div>
                        <div className='uii-bottom'>
                            {email}
                        </div>
                    </div><div className='user-info-item'>
                        <div className='uii-top'>
                            City
                        </div>
                        <div className='uii-bottom'>
                            {city}
                        </div>
                    </div><div className='user-info-item'>
                        <div className='uii-top'>
                            State/Province
                        </div>
                        <div className='uii-bottom'>
                            {locationState}
                        </div>
                    </div><div className='user-info-item'>
                        <div className='uii-top'>
                            Country
                        </div>
                        <div className='uii-bottom'>
                            {country}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {   
            userEditing ? 
                <UserAdd onSaveHandler={handleUserEdit} onCloseHandler={handleUserEditClose} userData={data} /> : ''
        }
        {
            deleteRequest ?
                <WarningPopup onSubmitHandler={handleUserDelete} onCancelHandler={handleUserDeleteClose} /> : ''
        }
        </>
    )

}

export default UserProfile;