import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Redirect } from "react-router-dom";

import Dashboard from '../app/views/Dashboard/Dashboard';
import Users from '../app/views/Users/Users';

const AppRoutes = ({sessionState}) => {

    const action = useSelector(state => state.action);
    const [loggingIn,setLoggingIn] = useState(false);
    const [loginAttempt,setLoginAttempt] = useState(false);

    useEffect(() => {
        if(action.loggingIn){
          setLoginAttempt(true);
          setLoggingIn(true);
          return;
        }
        setLoginAttempt(false);
        setLoggingIn(false);
      }, [action]); 

    return (
        <div className={
            loggingIn ? 'blur-frame' : 
                !loginAttempt && !sessionState ? 'hidden' : ''
        }>
            <Router>
                <Routes>
                    <Route exact path={'/'} element={<Dashboard />} />
                    <Route exact path={'/users'} element={<Users />} />
                </Routes>
            </Router>
        </div>
    )
}

export default AppRoutes;