const init = {
	backgroundEffect: true,
	browser: false
};

export const settingsReducer = (state = { ...init }, action) => {
	switch (action.type) {

		case 'SET_BACKGROUND_EFFECT':
			return Object.assign({}, state, {
				backgroundEffect: action.backgroundEffect
			});

		case 'SET_BROWSER':
			return Object.assign({}, state, {
				browser: action.browser
			});

		default:
			return state;

	};
};