import React from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table';

const Table = ({data, columns, clickHandler, clickHandlerTriggers = []}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, 
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
      } = useTable({columns,data},
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
      );

  
    const handleRowClicked = (id) => {
        if(id){
            clickHandler(id);
            return;
        }
        return;
    }

    return (
        <>
        <table {...getTableProps()} className='global-table-component'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if(clickHandlerTriggers.includes(cell.column.class)){
                      return <td {...cell.getCellProps()} onClick={() => handleRowClicked(row.original.id)}>{cell.render("Cell")}</td>;
                  }
                  return <td {...cell.getCellProps()} >{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>


      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={state.pageSize}
          onChange={e => {
              setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      </>
    )

    /*return (
        <div className={header ? 'table-row header' : id ? 'table-row clickable' : 'table-row'} onClick={handleRowClicked}>
            {
                data.map((row) => {
                    return <TableCell text={row.text} classes={row.class} id={row.id}  />
                })
            }
        </div>
    )*/
}

export default Table;