import React, {useState, useLayoutEffect, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import UserIcon from '../../../assets/images/icons/user.svg';

import CircularChart from '../../components/Charts/CircularChart';
import OverallChartComponent from '../../components/Charts/OverallChart';
import LineChartComponent from '../../components/Charts/LineChart';
import WordCloudComponent from '../../components/Charts/WordCloud';

import { BACKGROUND_EFFECT, SESSION_STATE, USER_ROLES, USER_DATA } from '../../../util/constants/AppConstants';

import Fader from '../../components/Loaders/Fader';

import axios from 'axios';

import Logo from '../../../assets/images/logo.png';
import TrendIcon from '../../../assets/images/icons/trend.svg';
import MentionsIcon from '../../../assets/images/icons/mentions.svg';

import EngagementIcon from '../../../assets/images/icons/engagement.svg';
import HashtagIcon from '../../../assets/images/icons/hashtag.svg';
import ShareIcon from '../../../assets/images/icons/share.svg';
import KeywordIcon from '../../../assets/images/icons/keyword.svg';

import TopicItem from '../../components/TopicItem';
import SocialPosts from '../../components/Social/SocialPosts';
import DataGridComponent from '../../components/Charts/DataGrid';

import DefaultUser from '../../../assets/images/tmp/default-user.jpg';

import { setSession } from '../../../datastore/actions/sessionActions';
import { setBackgroundEffect } from '../../../datastore/actions/settingsActions';
import { setNotify } from '../../../datastore/actions/actionActions';
import { getCustomState, saveCustomState } from '../../../util/LocalStorage';

import SwitchInput from '../../components/Forms/Switch';
import AppNotify from '../../components/AppNotify';

import { deleteCustomState } from '../../../util/LocalStorage';
import { setAxiosConfig } from '../../../util/SetDefaultLists';

import AppDatabase from '../../../datastore/app/app-data.json';

import Table from '../../components/Table/Table';
import UploadData from '../../components/Uploader/UploadData';
import UserProfile from '../../components/UserProfile/UserProfile';
import UserAdd from '../../components/UserAdd/UserAdd';

import FormButton from '../../components/Forms/FormButton';
import { act, render } from '@testing-library/react';

const Users = () => {

    const div = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const config = useSelector(state => state.config.config);
    const actions = useSelector(state => state.action);
    const appData = AppDatabase;

    const backgroundEffectFormInputProps = { inputProps: { 'aria-label': 'Background Colour' } };
    const backgroundEffectState = useSelector(state => state.settings.backgroundEffect);

    const [backgroundEffectChecked,setBackgroundEffectChecked] = useState(backgroundEffectState);
    const [booleanQuery,setBooleanQuery] = useState(false);
    const [isBooleanQueryEdit,setIsBooleanQueryEdit] = useState(false);
    const [isBooleanSaving,setIsBooleanSaving] = useState(false);

    const [title,setTitle] = useState('Searches');
    const [notifyNum,setNotifyNum] = useState(0);

    const [dataPreviewLoading,setDataPreviewLoading] = useState(true);
    const [userData,setUserData] = useState(false);

    const [userDataState,setUserDataState] = useState(false);
    const [userInfo,setUserInfo] = useState(false);

    const [dataSourcesLoading,setDataSourcesLoading] = useState(true);

    const [overallSentimentPositive,setOverallSentimentPositive] = useState(600);
    const [overallSentimentNegative,setOverallSentimentNegative] = useState(200);

    const [taggingActiveLoading,setTaggingActiveLoading] = useState(true);
    const [activeNameList,setActiveNameList] = useState([]);
    const [activeUserId,setActiveUserId] = useState(false);

    const [isUserMenuOpened,setIsUserMenuOpened] = useState(false);
    const [isUserLoaded,setIsUserLoaded] = useState(false);
    const [isAddUser,setIsAddUser] = useState(false);

    const [menuLoading,setMenuLoading] = useState(true);
    const [menu,setMenu] = useState(appData['menu']);

    const [activeUserProfile,setActiveUserProfile] = useState(false);
    const [activeUserProfileData,setActiveUserProfileData] = useState(false);
    const [activeUserProfileLoading,setActiveUserProfileLoading] = useState(false);
    const [activeUserData,setActiveUserData] = useState(false);
    const [nameData,setNameData] = useState(false);

    const [isTaggingActive,setIsTaggingActive] = useState(false);
  
    const handleOpenUserMenu = () => {
        if(!isUserMenuOpened){
            setIsUserMenuOpened(true);
            return;
        }
        setIsUserMenuOpened(false);
    }

    const handleLogout = () => {
        deleteCustomState(SESSION_STATE);
        setIsUserMenuOpened(false);
        dispatch(setSession(false));
    }

    const handleChangeBackgroundStatus = (status) => {
        saveCustomState(BACKGROUND_EFFECT,status);
        dispatch(setBackgroundEffect(status));
    }

    const handleUserStatus = (status) => {
        let statusLabel = '';
        if(status === 2){
            statusLabel = 'Active';
        }
        return <><span className='status-label'>{statusLabel}</span></>
    }

    const addSearchTopic = (id,list) => {
        setIsTaggingActive(true);
        setActiveNameList(list);
        setActiveUserId(id);
        setTaggingActiveLoading(false);
    }

    const handleUserSearches = (searches,id) => {
        return searches.map((search) => {
            if(search === '+'){
                return <span className='search-label add' onClick={() => addSearchTopic(id,searches)}>+</span>;
            }
            return <span className='search-label'>{search}</span>
        });
    }

    const handleSetUserData = (userdata) => {
        let userArr = [];
        userdata.map((item) => 
            userArr.push(
                {
                    id: item.id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    email: item.email,
                    status: handleUserStatus(item.status),
                    searches: handleUserSearches(item.searches,item.id)
                }
            )
        );
        setUserData(userArr);
    }

    const handleGetUserData = () => {
        axios(
            setAxiosConfig(config, '/users/all')
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setDataPreviewLoading(false);
                    handleSetUserData(res.data.data);
                }else {
                    console.log('ERROR: Grabbing Users');
                }
            }
        ).catch(err => {
            console.log(err)
        })

    }


    const tableHeader = [
        {
            Header: 'First Name',
            accessor: 'firstname',
            class: 'date-th'
        },
        {
            Header: 'Last Name',
            accessor: 'lastname',
            class: 'headline-th'
        },
        {
            Header: 'Email',
            accessor: 'email',
            class: 'url-th'
        },
        {
            Header: 'Status',
            accessor: 'status',
            class: 'opentext-th'
        },
        {
            Header: 'Searches/Topics',
            accessor: 'searches',
            class: 'hit-th'
        }
    ];

    const userTableColumns = useMemo(() => tableHeader);

    const handleUserCreate = () => {
        setIsAddUser(false);
        handleGetUserData();
    }

    const handleUserCreateClose = () => {
        setIsAddUser(false);
    }

    const handleAddUser = () => {
        setIsAddUser(true);
    }

    const handleDeleteSuccess = () => {
        setActiveUserProfile(false);
        handleGetUserData();
    }

    const handleMenuSelection = (slug,label) => {
        navigate('/' + slug);
    }

    const handleUserClick = (id) => {
        setActiveUserProfileLoading(true);
        axios(
            setAxiosConfig(config, '/users/info?id=' + id)
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setDataPreviewLoading(false);
                    setActiveUserProfileData(res.data.data);
                    setActiveUserProfile(res.data.data);
                    setActiveUserProfileLoading(false);
                    setActiveUserData(res.data.data);
                }else {
                    console.log('ERROR: Grabbing Users');
                }
            }
        ).catch(err => {
            console.log(err)
        })

    }

    const renderActiveNames = (name) => {
        const newNames = [...activeNameList];
        const index = newNames.indexOf(name);
        if (index === -1) {
            newNames.push(name);
        } else {
            newNames.splice(index, 1);
        }
        setActiveNameList(newNames);
    }

    const handleGetNameData = () => {
        axios(
            setAxiosConfig(config, '/data/names')
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setNameData(res.data.data);
                }else {
                    console.log('ERROR: Grabbing Top Posts');
                }
            }
        ).catch(err => {
            console.log(err)
        })
    }

    const onTagsCancelHandler = () => {
        setIsTaggingActive(false);
        setActiveUserId(false);
        setActiveNameList([]);
    }

    const handleTaggingSubmit = () => {
        setTaggingActiveLoading(true);
        axios(
            setAxiosConfig(config, '/data/names/tags', 'post', {
                user_id: activeUserId,
                tags: activeNameList
            })
        ).then(
            res => {
                if(res.data.status === 'success'){
                    handleGetUserData();
                    setIsTaggingActive(false);
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    }));
                }else {
                    console.log('ERROR: Grabbing Top Posts');
                }
            }
        ).catch(err => {
            console.log(err)
        })


    }

    useEffect(() => {
        if(appData){
            setMenuLoading(false);
        }
    }, [appData]);

    useEffect(() => {
        if(dataPreviewLoading && config.api){
            handleGetUserData();
        }
    }, [dataPreviewLoading, config]);

      useEffect(() => {
        setBackgroundEffectChecked(backgroundEffectState);
      }, [backgroundEffectState])

      useEffect(() => {
        setActiveUserProfileData(activeUserProfile);
      }, [activeUserProfile])


      useLayoutEffect(() => {
        const divAnimate = (div.current.getBoundingClientRect().top) + 10;
        const onScroll = () => {
          if (divAnimate < window.scrollY) {
            div.current.style.position = "fixed";
            div.current.style.top = 0;
            div.current.className = 'pfr-header fixed';
          } else {
            div.current.className = 'pfr-header';
            div.current.style.position = "absolute";
          }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }, []);

      useEffect(() => {        
        if(config.api  && dataPreviewLoading){ handleGetNameData() }
      }, [config,nameData])

      useEffect(() => {
        if(!userInfo && userDataState && config.api && !actions.loggingIn){
            setUserInfo(userDataState);
        }
    }, [userDataState,userInfo,config,actions]);

    useEffect(() => {
        setUserDataState(getCustomState(USER_DATA, true));
    }, [])


    return <>
        <AppNotify />
        <div className='platform-frame'>
            <div className='pf-left'>
                <div className='logo'>
                    <img src={Logo} />
                    <div className='logo-sub'>Admin Portal</div>
                </div>
                <div className='menu'>
                    {
                        !menuLoading ?
                            menu.map((item) => {
                                return <TopicItem data={item} topicHandler={handleMenuSelection} />
                            }) : <Fader />
                    }
                </div>
                <div className='menu-footer'>
                    <div className='disclaimer'>
                        &copy; 2023 TAGA Group Inc.
                    </div>
                </div>
            </div><div className='pf-right'>
                <div ref={div} className='pfr-header'>
                    <div className='dashboard-title'>
                        Users
                    </div><div className='dashboard-menu'>
                        <div className='dm-left'>
                            {
                                /**
                                 *  @ Feature Flag :: OFF
                                 */
                            /*
                            <span>
                                <div></div>
                                <div></div>
                                <div></div>
                            </span>*/
                        }
                        </div><div className='dm-right' onClick={handleOpenUserMenu}>
                            <div className='user-icon'>
                                { notifyNum > 0 ? <div className='notify-num'>{notifyNum}</div> : '' }
                                <img src={UserIcon} />
                            </div>
                        </div>
                    </div>
                    {
                        isUserMenuOpened ? 
                            <>
                                <div className='user-menu'>
                                    <div className='user-frame'>
                                        <div className='username'>
                                            { userInfo ? 
                                                <>
                                                {userInfo.firstname} {userInfo.lastname} <span>{
                                                    USER_ROLES.map((role) => {
                                                        if(role.value == userInfo.role){
                                                            return role.short
                                                        } 
                                                    })
                                                }</span>
                                                </> : <Fader />
                                            }
                                        </div>
                                    </div>
                                    <div className='user-image'>
                                        {userInfo ? Array.from(userInfo.firstname)[0] + Array.from(userInfo.lastname)[0] : '...'}
                                    </div>
                                    <div className='user-info'>
                                        <span className='email'>{userInfo ? userInfo.email : '...'}</span>
                                        <span className='org'>{userInfo ? userInfo.job : '...'}</span>
                                    </div>
                                    <div className='logout'>
                                        <span onClick={handleLogout}>Logout</span>
                                    </div>
                                </div>
                            </> : ''
                    }
                </div>
                <div className='dashboard-components'>
                    <div className='dc-left full'>

                        <div className='sentiment-trend chart-component user-component'>
                            {
                                activeUserProfileLoading? <Fader /> : 
                                    activeUserProfile ? <UserProfile data={activeUserProfileData} onDeleteHandler={handleDeleteSuccess} /> : 'Please Select a User From the List Below'
                            }
                        </div>            


                        <div className='sentiment-trend chart-component'>
                            <div className='user-manager'>
                                <div className='edit-btn' onClick={handleAddUser}>Add New</div>
                            </div>
                            
                            {
                                !dataPreviewLoading ? 
                                <>
                                    <Table columns={userTableColumns} data={userData} clickHandler={handleUserClick} clickHandlerTriggers={['date-th','headline-th','url-th']} />                                
                                </>
                                
                                : 
                                    <div className='sentiment-charts'>
                                        <Fader />
                                    </div>
                            }
                        </div>


                            {
                                isAddUser ? 
                                    <UserAdd onSaveHandler={handleUserCreate} onCloseHandler={handleUserCreateClose} /> : ''
                            }
                            {
                                
                                    isTaggingActive ? 
                                            <>
                                            <div className='login-wrap user-add-wrap'>
                                                <div className='login-view'>
                                                    <div className='login-box add-user-box'>
                                                        <div className='logo-large'>
                                                            Tag Searches
                                                        </div>
                                                        { 
                                                            !taggingActiveLoading ?
                                                            <>
                                                                <div className='tags'>
                                                                    {
                                                                            nameData.map((item) => {
                                                                                if(activeNameList.includes(item.name)){
                                                                                    return <>
                                                                                        <div className='checkarea checked searcharea'>
                                                                                            <input type='checkbox' value={item.name} onClick={() => renderActiveNames(item.name)} checked />
                                                                                            <label>{item.name}</label>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                return <>
                                                                                    <div className='checkarea searcharea'>
                                                                                        <input type='checkbox' value={item.name} onClick={() => renderActiveNames(item.name)} />
                                                                                        <label>{item.name}</label>
                                                                                    </div>
                                                                                </>
                                                                            })
                                                                    }
                                                                </div>
                                                                <div className='submit delete'>
                                                                    <FormButton subLink={'Cancel'} subLinkClick={onTagsCancelHandler} text={'Save'} onClick={handleTaggingSubmit} />
                                                                </div>
                                                                </> : 
                                                                <Fader />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''
                            }




                    </div>





                </div>

                <div className='platform-footer'>
                    <div className='version-num'>
                        v0.0.1
                    </div>
                </div>


            </div>
                
        </div>
        <div className="background-elem"></div>
    </>;
}

export default Users;