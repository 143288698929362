import React, {useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Preload from './util/Preload';
import Login from './app/views/Login/Login';
import AppRoutes from './util/Routes';
import { getCustomState } from './util/LocalStorage';

function App() {

  const session = useSelector(state => state.session.session);
  const [sessionState,setSessionState] = useState(true);

  useEffect(() => {
      if(getCustomState('SESSION_STATE'))
      {
        setSessionState(true);
        document.body.classList.remove('login');
        return;
      }
      if(session){
          setSessionState(true);
          return;
      }
      document.body.classList.add('login');
      setSessionState(false);
  }, [session]);

  return (
    <div className="App">
      <Preload />
      {
        !sessionState ? <Login /> : ''
      }
      <AppRoutes sessionState={sessionState} />
    </div>
  );
}

export default App;
