import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputItem from '../../components/Forms/InputItem';
import FormButton from '../../components/Forms/FormButton';

import AppNotify from '../../components/AppNotify';

import { setSession } from '../../../datastore/actions/sessionActions';
import { setLoggingIn } from '../../../datastore/actions/actionActions';

import Logo from '../../../assets/images/logo.png';
import UsernameIcon from '../../../assets/images/icons/user.svg';
import PasswordIcon from '../../../assets/images/icons/password.svg';
import Fader from '../../components/Loaders/Fader';

import { createHash } from '../../../util/HelperFunctions';
import { saveCustomState } from '../../../util/LocalStorage';

import { SESSION_STATE, USER_DATA, SESSION_ID } from '../../../util/constants/AppConstants';

import { setSessionId } from '../../../datastore/actions/sessionActions';

import axios from 'axios';
import { setNotify } from '../../../datastore/actions/actionActions';
import { setAxiosConfig } from '../../../util/SetDefaultLists';

const Login = () => {

    const dispatch = useDispatch();

    const config = useSelector(state => state.config.config);

    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [loggingIn,setIsLoggingIn] = useState(false);
    const [loginChallenge,setLoginChallenge] = useState(false);

    const handleUsername = (e) => {
        var usernameValue = e.currentTarget.value;
        setUsername(usernameValue);
    }

    const handlePassword = (e) => {
        var passwordValue = e.currentTarget.value;
        setPassword(passwordValue);
    }


    const handleLogin = () => {

        if(!username || !password || username === '' || password === '')
        {
            dispatch(
                setNotify({
                    status: true,
                    type: 'error',
                    title: 'Error',
                    message: 'Password and/or Email Missing'
                })
            )
            return;
        }

        setIsLoggingIn(true);
        setLoginChallenge(true);
        dispatch(setLoggingIn(true));

            axios(
                setAxiosConfig(config, '/auth/login', 'post', {
                    email: username,
                    password: password,
                    admin: true
                })
            ).then(
                res => {
                    if(res.data.status === 'success'){

                        let userData = res.data.data;
                        
                        saveCustomState(USER_DATA, {
                            id: userData.user.id,
                            firstname: userData.user.firstname,
                            lastname: userData.user.lastname,
                            email: userData.user.email,
                            role: userData.user.role,
                            job: userData.user.job_title
                        }, true);

                        dispatch(setSessionId(userData.session.id));
                        dispatch(setSession(userData.session.token));

                        saveCustomState(SESSION_STATE, userData.session.token);
                        saveCustomState(SESSION_ID, userData.session.id);
                
                        setTimeout(function()
                        {
                            dispatch(setLoggingIn(false));
                            setLoginChallenge(false);
                        }, 500);

                    }else {
                        setIsLoggingIn(false);
                        setLoginChallenge(false);
                        dispatch(setLoggingIn(false));
                        dispatch(
                            setNotify({
                                status: true,
                                type: 'error',
                                title: 'Error',
                                message: res.data.message
                            })
                        )
                    }
                }
            ).catch(err => {
                console.log(err)
            })
    
    }


    return (
        <>
        <AppNotify />
        <div className={loginChallenge ? 'login-wrap logging-in' : 'login-wrap'}>
            <div className='login-view'>
                <div className='login-box'>
                    <div className='login-logo'>
                        <img src={Logo} />
                        <div className='logo-sub'>Admin Portal</div>
                    </div>
                    {
                        !loginChallenge ?
                            <>
                                <InputItem label='Username' type={'text'} icon={UsernameIcon} onChange={handleUsername} value={username} />
                                <InputItem label='Password' type={'password'} icon={PasswordIcon} onChange={handlePassword} value={password} />
                                <div className='submit'>
                                    <FormButton text={'Login'} onClick={handleLogin} />
                                </div>
                            </> 
                        : <Fader size={50} />
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default Login;