import React, { useState, useEffect} from 'react';
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux';

import InputItem from '../../components/Forms/InputItem';
import FormButton from '../../components/Forms/FormButton';
import SelectItem from '../Forms/SelectItem';

import UsernameIcon from '../../../assets/images/icons/user.svg';
import PasswordIcon from '../../../assets/images/icons/password.svg';
import EngagementIcon from '../../../assets/images/icons/engagement.svg';
import JobIcon from '../../../assets/images/icons/job.svg';
import EmailIcon from '../../../assets/images/icons/email.svg';
import CityIcon from '../../../assets/images/icons/city.svg';
import StateIcon from '../../../assets/images/icons/state.svg';
import CountryIcon from '../../../assets/images/icons/globe.svg';
import UserRoleIcon from '../../../assets/images/icons/role.svg';

import Fader from '../../components/Loaders/Fader';

import { createHash } from '../../../util/HelperFunctions';
import { saveCustomState } from '../../../util/LocalStorage';

import { USER_ROLES } from '../../../util/constants/AppConstants';

import axios from 'axios';
import { setAxiosConfig } from '../../../util/SetDefaultLists';
import { setNotify } from '../../../datastore/actions/actionActions';

const UserAdd = ({onSaveHandler,onCloseHandler,userData}) => {

    const dispatch = useDispatch();

    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [jobTitle,setJobTitle] = useState('');
    const [city,setCity] = useState('');
    const [locationState,setLocationState] = useState('');
    const [country,setCountry] = useState('');
    const [userRole,setUserRole] = useState('');

    const [password,setPassword] = useState('');

    const [saving,setSaving] = useState(false);

    const config = useSelector(state => state.config.config);

    const handleFirstname = (e) => {
        var usernameValue = e.currentTarget.value;
        setFirstname(usernameValue);
    }

    const handleLastname = (e) => {
        var usernameValue = e.currentTarget.value;
        setLastname(usernameValue);
    }

    const handleEmail = (e) => {
        var usernameValue = e.currentTarget.value;
        setEmail(usernameValue);
    }

    const handlePhone = (e) => {
        var usernameValue = e.currentTarget.value;
        setPhone(usernameValue);
    }

    const handleJobTitle = (e) => {
        var value = e.currentTarget.value;
        setJobTitle(value);
    }

    const handleCity = (e) => {
        var value = e.currentTarget.value;
        setCity(value);
    }

    const handleUserRole = (e) => {
        var value = e.currentTarget.value;
        setUserRole(value);
    }

    const handleCountry = (e) => {
        var value = e.currentTarget.value;
        setCountry(value);
    }

    const handleLocationState = (e) => {
        var value = e.currentTarget.value;
        setLocationState(value);
    }

    const handlePassword = (e) => {
        var passwordValue = e.currentTarget.value;
        setPassword(passwordValue);
    }

    const handleSave = () => {
        setSaving(true);
        axios(
            setAxiosConfig(config, '/users/' + userData.id + '/update', 'post', {
                firstname: firstname,
                lastname: lastname,
                email: email,
                phone: phone,
                job_title: jobTitle,
                location_city: city,
                location_state: locationState,
                location_country: country,
                role: userRole,
                password: password
            })
        ).then(
            res => {
                if(res.data.status === 'success'){
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    }));
                    onCloseHandler();
                }else {
                    dispatch(setNotify({
                        status: true,
                        type: 'error',
                        title: 'Error',
                        message: res.data.message
                    }));
                }
                setSaving(false);
            }
        ).catch(err => {
            console.log(err)
        })
    }



    const handleCreate = () => {
        setSaving(true);
        axios(
            setAxiosConfig(config, '/users/create', 'post', {
                firstname: firstname,
                lastname: lastname,
                email: email,
                phone: phone,
                password: password,
                job_title: jobTitle,
                location_city: city,
                location_state: locationState,
                location_country: country,
                role: userRole
            })
        ).then(
            res => {
                if(res.data.status === 'success'){
                    dispatch(setNotify({
                        status: true,
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    }));
                    onSaveHandler();
                }else {
                    dispatch(setNotify({
                        status: true,
                        type: 'error',
                        title: 'Error',
                        message: res.data.message
                    }));
                }
                setSaving(false);
            }
        ).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(userData){
            setFirstname(userData.firstname);
            setLastname(userData.lastname);
            setEmail(userData.email);
            setPhone(userData.phone);
            setJobTitle(userData.job_title);
            setLocationState(userData.location_state);
            setCity(userData.location_city);
            setCountry(userData.location_country);
        }
    }, [userData]);


    return (
        <div className='login-wrap user-add-wrap'>
            <div className='login-view'>
                <div className='login-box add-user-box'>
                    { !saving ? 
                        <div className='logo-large'>{ userData ? 'Edit User' : 'Create A New User'}</div> : 
                            <>
                            <div className='logo-large'>{ userData ? 'Saving User Information' : 'Creating User' }</div> 
                            <div className='logo-sub user-space'>The process should take no longer than 30 seconds. Please Wait...</div>
                            </>
                    }
                    {
                        !saving ?
                            <>
                                <div className='add-user-left'>
                                    <InputItem label='First Name' type={'text'} icon={UsernameIcon} onChange={handleFirstname} value={firstname} />
                                    <InputItem label='Last Name' type={'text'} icon={UsernameIcon} onChange={handleLastname} value={lastname}  />
                                    <InputItem label='Email' type={'email'} icon={EmailIcon} onChange={handleEmail} value={email} />
                                    <InputItem label='Password' type={'password'} icon={PasswordIcon} onChange={handlePassword} value={password} />
                                    <InputItem label='Phone' type={'text'} icon={EngagementIcon} onChange={handlePhone} value={phone} />
                                </div><div className='add-user-right'>
                                    <InputItem label='Job Title' type={'text'} icon={JobIcon} onChange={handleJobTitle} value={jobTitle} />
                                    <InputItem label='City' type={'text'} icon={CityIcon} onChange={handleCity} value={city} />
                                    <InputItem label='Province/State' type={'text'} icon={StateIcon} onChange={handleLocationState} value={locationState} />
                                    <InputItem label='Country' type={'text'} icon={CountryIcon} onChange={handleCountry} value={country} />
                                    <SelectItem label="Change User Role" icon={UserRoleIcon} onChangeHandler={handleUserRole} options={USER_ROLES} />
                                </div>
                                <div className='submit'>
                                    <FormButton subLink={'Cancel'} subLinkClick={onCloseHandler} text={userData? 'Save' : 'Create'} onClick={userData ? handleSave : handleCreate} />
                                </div>
                            </> 
                        : <Fader size={50} />
                    }
                </div>
            </div>
        </div>
    )
}

export default UserAdd;